
import React, { useEffect, useState } from "react";

import { navigate, Router } from "@reach/router";
import { Button, Divider, Form, Icon, Input, Modal, Table, Tag } from 'antd';
import { useGlobalState } from "../../state";
import { getCompanies, newCompany } from "../../state/actions";
import CompanyPage from "./company";
import { Company } from "../../service/src/models/Company";
const { Column, ColumnGroup } = Table;

const newCompanyForm = (props) => {

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;

    // Only show error after a field is touched.
    const nameError = isFieldTouched('Name') && getFieldError('Name');
    const orgidError = isFieldTouched('OrgId') && getFieldError('OrgId');
    const descriptionError = isFieldTouched('Description') && getFieldError('Description');

    useEffect(() => {
        props.form.validateFields();
    }, []);

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some((field) => fieldsError[field]);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                console.log('Form is valid. Lets login');

                const request = await newCompany({ request: { name: values.Name, description: values.Description, orgId: values.OrgId } });

            }
        });
    };

    return (

        <Form layout="vertical" onSubmit={handleSubmit}>
            <Form.Item validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
                {getFieldDecorator('Name', {
                    rules: [{ required: true, message: 'Please input name!' }],
                })(
                    <Input
                        disabled={isCreating}
                        prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Name"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={orgidError ? 'error' : ''} help={orgidError || ''}>
                {getFieldDecorator('OrgId', {
                    rules: [{ required: true, message: 'Please input orgId!' }],
                })(
                    <Input
                        disabled={isCreating}
                        prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="number"
                        placeholder="OrgId"
                    />,
                )}
            </Form.Item>
            <Form.Item validateStatus={descriptionError ? 'error' : ''} help={descriptionError || ''}>
                {getFieldDecorator('Description', {
                    rules: [{ required: true, message: 'Please input description!' }],
                })(
                    <Input
                        disabled={isCreating}
                        prefix={<Icon type="question" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="text"
                        placeholder="Description"
                    />,
                )}
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={isCreating} disabled={hasErrors(getFieldsError())}>
                    Create
              </Button>
            </Form.Item>
        </Form>
    );
};


function CompaniesList({ path }) {

    const [newCustomerModalVisible, setNewCustomerModalVisible] = useState<boolean>(false);
    const [companies] = useGlobalState('companies');

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const result = await getCompanies();
        console.log(result);
    }

    const showModal = () => {
        setNewCustomerModalVisible(true);
    };

    const handleOk = (e) => {
        console.log(e);
        setNewCustomerModalVisible(false);
    };

    const handleCancel = (e) => {
        console.log(e);
        setNewCustomerModalVisible(false);
    };

    const NewCompanyFormHorizontal = Form.create({ name: 'horizontal_login' })(newCompanyForm);

    const onSelect = (e) => {
        console.log(e);
    };

    function onClick(record) {
        navigate('/dashboard/companies/' + record.companyId);
    }

    return (
        <div>
            <Button size="large" type="primary" onClick={() => { showModal() }} >+ company</Button>

            <Table dataSource={companies} onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => { onClick(record); }
                };
            }}>
                <Column
                    title="Id"
                    dataIndex="companyId"
                    key="companyId"
                />

                <Column
                    title="Name"
                    dataIndex="name"
                    key="name"
                />

                <Column
                    title="OrgId"
                    dataIndex="orgId"
                    key="orgId"
                />

                <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                />

                <Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                        <span>
                            <a>View</a>
                        </span>
                    )}
                />

                <Column
                    title="Services"
                    key="services"
                    render={(text, record: Company) => (
                        <Tag color="green" key={record.companyId}>
                            {record.services.length}
                        </Tag>
                    )}
                />
            </Table>

            <Modal
                title="New Customer"
                visible={newCustomerModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Return
                    </Button>,
                ]}
            >
                <NewCompanyFormHorizontal />
            </Modal>
        </div >
    );
}


function Companies() {

    return (
        <div>
            <Router>
                <CompaniesList path="/dashboard/companies/" />
                <CompanyPage path="/dashboard/companies/:companyId" />
            </Router>
        </div >
    );
}

export default Companies;
