import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../state";
import { getCompany, newCompanyService, getCompanyServiceSettingValues, setCompanyServiceSettingValues, getServices } from "../../state/actions";
import { Company, Service, ServiceSetting, CompanyPatchServiceSettingValuesRequest, ServiceSettingValue } from "../../service/src";

// @ts-ignore
import styles from "./company.module.scss";
import { Descriptions, Table, Button, Modal, Select, Divider, Input, Form, Icon } from "antd";
import Column from "antd/lib/table/Column";
import DateTimeTag from "../../components/General/DateTimeTag";
import { FormComponentProps } from "antd/lib/form";
const { Option } = Select;


interface IServiceSettingValuesFormProps extends FormComponentProps {
    service: Service;
    company: Company;
}

const ServiceSettingValuesForm = (props: IServiceSettingValuesFormProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [serviceSettingValues] = useGlobalState('serviceSettingValues');
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form;

    // Only show error after a field is touched.
    const keyError = isFieldTouched('Key') && getFieldError('Key');

    useEffect(() => {
        props.form.validateFields();
    }, []);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        setIsLoading(true);
        const result = await getCompanyServiceSettingValues(props.company.companyId, props.service.serviceId);
        setIsLoading(false);
        props.form.resetFields(); // For some reason we have to to this unless we want old initialValues
    }

    function hasErrors(fieldsError) {
        return Object.keys(fieldsError).some((field) => fieldsError[field]);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                console.log('Form is valid.');

                const request: CompanyPatchServiceSettingValuesRequest = {};
                request.serviceSettingValues = [];

                const keys = Object.keys(values);
                for (const key of keys) {
                    let _value = "";
                    if (values[key] !== undefined) { _value = values[key]; }

                    const i = serviceSettingValues.findIndex((ssv: ServiceSettingValue) => ssv.serviceSettingId === key && ssv.companyId === props.company.companyId);
                    let dv = "";
                    if (i !== -1) { dv = serviceSettingValues[i].serviceSettingValueId; }

                    request.serviceSettingValues.push({ companyId: props.company.companyId, serviceId: props.service.serviceId, value: _value, serviceSettingId: key, serviceSettingValueId: dv });
                }
                const _request = await setCompanyServiceSettingValues(props.company.companyId, props.service.serviceId, request);
            }
        });
    };

    const formItems = props.service.serviceSettings.map((item: ServiceSetting) => {
        const i = serviceSettingValues.findIndex((ssv: ServiceSettingValue) => ssv.serviceSettingId === item.serviceSettingId && ssv.companyId === props.company.companyId);
        let dv;
        if (i !== -1) { dv = serviceSettingValues[i].value; }

        return (
            <Form.Item key={item.key} validateStatus={keyError ? 'error' : ''} help={keyError || ''}>
                {getFieldDecorator(item.serviceSettingId, {
                    initialValue: dv,
                    rules: [{ required: false, message: 'Please input ' + item.key, }],

                })(
                    <Input
                        disabled={isCreating}
                        prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={item.key}
                    />,
                )}
            </Form.Item>
        );
    });

    return (
        <Form layout="vertical" onSubmit={handleSubmit}>
            {formItems}
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={isCreating || isLoading} disabled={hasErrors(getFieldsError())}>
                    Create
              </Button>
            </Form.Item>
        </Form>
    );
};


interface IConfigureServiceSettings {
    service: Service;
    company: Company;
}

function ConfigureServiceSettings(props: IConfigureServiceSettings) {
    const ServiceSettingValues = Form.create<IServiceSettingValuesFormProps>({ name: 'horizontal_login' })(ServiceSettingValuesForm);

    return (
        <div><h1>{props.service.name}</h1><br />
            <ServiceSettingValues company={props.company} service={props.service} />
        </div>
    );
}

interface INewService {
    company: Company;
}

function NewService(props: INewService) {

    const [services] = useGlobalState('services');
    const [serviceIdToAdd, setServiceIdToAdd] = useState<string>();

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    //const [isDisabled, setIsSaving] = useState<boolean>(false);

    function onChange(value) {
        setIsDisabled(false);
        setServiceIdToAdd(value);
    }

    function onBlur() {
    }

    function onFocus() {
    }

    function onSearch(val) {
    }

    const avilableServices = services.map((avilableService: Service) => {
        const i = props.company.services.findIndex(service => service.serviceId === avilableService.serviceId);
        if (i === -1) {
            return (<Option key={avilableService.serviceId} value={avilableService.serviceId}>{avilableService.name}</Option>);
        }
    });

    async function addService() {
        setIsSaving(true);
        const result = await newCompanyService(props.company.companyId, { serviceId: serviceIdToAdd });
        setIsSaving(false);
        setIsDisabled(true);
    }

    return (
        <div>
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a service"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {avilableServices}
            </Select>
            <Divider type="vertical" />
            <Button size="default" type="primary" loading={isSaving} disabled={isDisabled} onClick={() => { addService() }}>add service</Button>
        </div>
    );
}

const CompanyPage = (props) => {

    const [configureServiceSettingsModalVisible, setConfigureServiceSettingsModalVisible] = useState<boolean>(false);
    const [newServiceModalVisible, setNewServiceModalVisible] = useState<boolean>(false);
    const [servicesTable, setServicesTable] = useState<Service[]>([]);
    const [service, setService] = useState<Service>();
    const [company, setCompany] = useState<Company>({});
    const [companies] = useGlobalState('companies');
    const [services] = useGlobalState('services');

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const i = companies.findIndex(company => company.companyId === props.companyId);

        //console.log(i);

        if (i !== -1) {
            setCompany(companies[i]);

            // This needs work
            if (services.length !== 0) {
                const items = companies[i].services.map(item => {
                    const ii = services.findIndex(service => service.serviceId === item.serviceId);
                    return services[ii];
                });
                setServicesTable(items);
            }
        }
    }, [companies, services]);

    async function getData() {
        const result = await getCompany({ companyId: props.companyId });
        const services = await getServices();
    }

    const showModal = () => {
        setNewServiceModalVisible(true);
    };

    const handleOk = (e) => {
        console.log(e);
        setNewServiceModalVisible(false);
    };

    const handleCancel = (e) => {
        console.log(e);
        setNewServiceModalVisible(false);
    };

    const serviceSettingsShowModal = () => {
        setConfigureServiceSettingsModalVisible(true);
    };

    const serviceSettingsHandleOk = (e) => {
        console.log(e);
        setConfigureServiceSettingsModalVisible(false);
    };

    const serviceSettingsHandleCancel = (e) => {
        console.log(e);
        setConfigureServiceSettingsModalVisible(false);
    };


    function onClick(record: Service) {
        setService(record);
        serviceSettingsShowModal();
        //navigate('/dashboard/companies/' + record.companyId);
    }

    return (
        <div>
            <small>{props.companyId}</small>
            <div className={styles.title}>{company.name}</div>

            <div style={{ maxWidth: '500px' }}>
                <Descriptions title="" bordered={true} size="small" column={1}>
                    <Descriptions.Item label="OrgId">{company.orgId}</Descriptions.Item>
                    <Descriptions.Item label="Description">{company.description}</Descriptions.Item>
                    <Descriptions.Item label="Created"><DateTimeTag timestamp={company.created} /></Descriptions.Item>
                    <Descriptions.Item label="Last Updated"><DateTimeTag timestamp={company.lastUpdated} /></Descriptions.Item>
                </Descriptions>
            </div>

            <br />
            <Button size="large" type="primary" onClick={() => { showModal(); }} >+ service</Button>
            <br />

            <Table dataSource={servicesTable} rowKey="serviceId" onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => { onClick(record); }
                };
            }}>
                <Column
                    title="Service Id"
                    dataIndex="serviceId"
                    key="serviceId"
                />
                <Column
                    title="Name"
                    dataIndex="name"
                    key="name"
                />
            </Table>

            <Modal
                title="New service"
                visible={newServiceModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Return
                    </Button>,
                ]}
            >
                <NewService company={company} />
            </Modal>


            <Modal
                title="Configure service settings"
                visible={configureServiceSettingsModalVisible}
                onOk={serviceSettingsHandleOk}
                onCancel={serviceSettingsHandleCancel}
                footer={[
                    <Button key="back" onClick={serviceSettingsHandleCancel}>
                        Return
                    </Button>,
                ]}
            >
                <ConfigureServiceSettings service={service} company={company} />
            </Modal>

        </div>
    );
};

export default CompanyPage;
